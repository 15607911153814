import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { loginUser } from '../models/Users'
import { getMessage } from '../helpers/Messages'
import Loading from '../components/Loading'
import InputUnstyled from '@mui/base/InputUnstyled'
import { styled } from '@mui/material/styles'

const Input = styled(InputUnstyled)(({ theme }) => ({
    'input, textarea': {
        backgroundColor: 'black',
        border: '1px solid white',
        borderRadius: '4px',
        color: theme.palette.white.main,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: 300,
        height: '45px',
        lineHeight: '20px',
        margin: ' 0 0 10px',
        outlineColor: theme.palette.white.main,
        padding: '0 12px',
        width: '100%',

        '&::placeholder': {
            color: theme.palette.white.main,
            textTransform: 'uppercase'
        },
    },
}))

const LoginForm = ({ dispatch, message }) => {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }} = useForm()
    const [details, setDetails] = useState({})
    const handleChange = (e, type) => {
        setDetails({ ...details, [type]: e.target.value })
    }
    const submitFunction = () => {
        setLoading(true)
        const { email, password } = details
        loginUser(dispatch, { email, password }).then(() => {
            setLoading(false)
        })
    }
    return (
        <Box component="form" onSubmit={handleSubmit(() => {
            submitFunction()
        })}>
            <Grid pt={8} container={true} flexDirection={['column', 'column', 'column', 'column', 'row']}>
                <Grid item xs={12} mb={errors && errors.email ? 1 : 0 }>
                    <Input
                        {...register(
                            'email',
                            { onChange: (e) => handleChange(e, 'email'),
                                required: 'Cannot be empty', pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: getMessage('formEmailInvalid'), 
                                }
                            })}
                        fullWidth
                        autoComplete='off'
                        
                        type='text'
                        name='email'
                        placeholder='Email'
                    />
                    { errors && errors.email && <Box fontSize='12px' color='red.main' fontWeight='bold'>{errors.email.message}</Box> }
                </Grid>
                <Grid item xs={12} mt={errors && errors.email ? 1 : 2} mb={errors && errors.password ? 0 : 0}>
                    <Input
                        {...register('password', { 
                            onChange: (e) => handleChange(e, 'password'),
                            required: 'Cannot be empty', pattern: {
                                value: /.{6,}/i,
                                message: getMessage('formPasswordInvalid'),
                            }})
                        }
                        fullWidth
                        autoComplete='new-password'
                        type='password'
                        placeholder='Password'
                    />
                </Grid>
                {
                    errors && errors.password ?
                        <Box color='red.main' fontSize='12px' fontWeight='bold'>{errors.password.message}</Box>
                        : null
                }
                {
                    message ?
                        <Box color='red.main' fontSize='12px' fontWeight='bold'>{message}</Box>
                        : null
                }
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} mb={3} mt={4}>
                    {
                        !loading ?
                            <>
                                <Button type='submit' variant='primary'>Login</Button>
                            </>
                            : <Box>
                                <Loading />
                            </Box>
                    }
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography textAlign='center' variant='small'>{getMessage('loginDefault')}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
function mapStateToProps(state) {
    return {
        errors: state.Auth.errors,
        message: state.User.message,
    }
}
export default connect(mapStateToProps)(LoginForm)
