import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { StaticImage } from "gatsby-plugin-image"
import LoginForm from '../modules/LoginForm'

const LoginPage = () => {
    return (
        <Box bgcolor="black.off" sx={{ 
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
        }}>
            <Box height="100%" width="100%" sx={{ position: 'relative', zIndex: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} height='100%' width='100%'>
                    <Grid
                        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
                        container={true}
                        height='100%'
                        justifyContent='center'
                        width='100%'
                        maxWidth="300px"
                        mt={[30, 30, 20, 20, 10, 10, 0]}>
                        <Grid item xs={12} color='white.main' alignItems='center' sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                <StaticImage src="../assets/images/barcodeWhite.png" alt="" />
                                <Typography mt={1} variant='h1'>CCS MANAGER</Typography>
                            </Box>
                            <LoginForm />
                            <Box mt={5}>
                                <StaticImage src="../assets/images/logoWhite.png" alt="" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 1,
            }}>
                <StaticImage src="../assets/images/barcodeCircular.png" alt="" />
            </Box>
            <Box sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: 1,
            }}>
                <StaticImage src="../assets/svg/backgroundMark.svg" alt="" />
            </Box>
        </Box>
    )
}

export default LoginPage

export const Head = () => <title>Login Page</title>
